import React from "react"
import { Link } from "gatsby"
import {
  createProductUrl,
  createBrandUrl,
  generateSemiRandomNumber,
  capitalizeFirstLetter,
} from "./utilityFunctions"

var suitableList = ["lämpar sig bra", "passar bra", "är bra", "funkar bra"]
var readMoreList = [
  "Vill du läsa mer om detta så gå gärna in på",
  "Du kan med fördel läsa på lite mer på",
  "Vill du ha mer info så hittar du mer under",
  "Du hittar en mer utförlig guide på",
]
var batteryIndication = [
  "Batterikapaciteten ger en bra indikation på hur långt man kan cykla, men säger inte allt.",
  "Storleken på batteriet kan ge en hint om hur långt det räcker men det finns flera faktorer som spelar in.",
  "Kapaciteten på batteriet kan ge ett hum om hur långt du kan cykla, men ofta måste man kolla på fler faktorer för att få hela bilden.",
]
var batteryMoreFactors = [
  "Faktorer så som vikt på cykeln, motorplacering och motormodell påverkar också räckvidden. Vi rekommenderar alltid att man läsar på om cykeln för att se vad tillverkaren uppskattar räckvidden till.",
  "Tillexempel så kommer du längre på en lätt cykel samtidigt som en mittmotor kan utnyttja växlarna och därmed driva på ett mer effektivt sätt. Tillverkarna har ofta uppskattat räckvidden men det kan vara bra att även läsa recensioner och se vad räckvidden är i verkligheten.",
  "En tung vikt förbrukar batteri snabbare än en lätt, en mittmotor kan utnyttja växlarna och skona batteriet, och en modernare motor kan vara mer effektiv. Det finns även fler faktorer som påverkar så bäst är att kika på tillverkarens uppskattade räckvidd.",
]
var rangeDescriptionText = [
  "Cykeln har en genomsnittsräckvidd på ",
  "Den genomsnittliga räckvidden är ",
  "I normala fall är räckvidden cirka ",
  "I genomsnitt kommer du ungefär ",
  "I normalfall kommer du runt ",
]
var rangeLess1 = [
  " km. Detta är mindre än snittet som ligger på runt 55 - 65 km för de cyklar vi har listade. ",
  " km vilket är mindre än genomsnittet. ",
  " km. I snitt ligger cyklarna vi har listade någonstans mellan 55 och 65 km i räckvidd. ",
  " km. Detta är något lågt men kan ibland behöver man inte längre räckvidd. ",
]
var rangeLess2 = [
  "Vet du med dig att du inte ska cykla så långa streckor mellan laddningarna kan detta vara helt okej, annars bör du kika på en annan cykel med längre räckvidd. ",
  "Ska du cykla långt utan tillgång till laddare bör du fundera en extra gång om detta verkligen är cykeln för dig. ",
  "Cyklar du ofta längre än så bör du köpa en annan cykel. ",
  "Räckvidden är framförallt viktig om du cyklar längre sträckor utan tillgång till laddare, då behöver du välja en cykel med lite längre räckvidd. ",
]
var rangeMid1 = [
  " km. De flesta av de cyklar vi har listade ligger inom spannet 55 - 65 km, så även denna cykel. ",
  " km vilket är ganska nära snittet bland de cyklar vi har listade. ",
  " km vilket är ganska standard. ",
  " km. Detta är ganska normalt och en majoritet av cyklarna vi har listade kommer ungefär lika långt. ",
]
var rangeMid2 = [
  "En majoritet av alla som köper elcyklar klarar sig mycket bra på denna räckvidd. ",
  "De flesta behöver inte mycket längre räckvidd än så utan har regelbunden tillgång till laddare (hemma eller på jobbet). ",
  "Har du väldigt långt till tillexempel jobbet, och inte har möjlighet att ladda där, bör du fundera om du behöver en cykel med lite längre räckvidd. ",
  "Ett tips är att mäta sträckan till jobbet, och sedan räkna med att du vill kunna cykla fram och tillbaks utan att ladda. Tänk även på att du vill ha viss marginal. ",
]
var rangeAbove1 = [
  " km. Detta är längre än en majoritet av de cyklar vi har listade, som ofta ligger mellan 55 och 65 km på en laddning. ",
  " km vilket är en ganska bra räckvidd, över snittet bland våra cyklar. ",
  " km. Detta är en ganska så lång räckvidd och kan jämföras med en majoritet av våra cyklar som endast kommer mellan 55 och 65 km på en laddning i genomsnitt. ",
  " km vilket är längre än de flesta cyklar klarar av. ",
]
var rangeAbove2 = [
  "Ofta betalar man lite extra för den extra räckvidden, och behöver du den inte och vill spara pengar kan du kika på en billigare cykel. ",
  "En sak att tänkta på att längre räckvidd i regel betyder större batteri. Större bateri blir dyrare att ersätta och tar ofa längre tid att ladda. ",
  "Cyklar med så här lång räckvidd är i regel dyrare, vilket kan vara bra att ha i huvudet om du vet med dig att du aldrig kommer använda den långa räckvidden. ",
]
var frame_type_use_case = {
  damcykel:
    "för lite kortare cykling. Till exempel inne i stan eller till matbutiken. Passar även till jobbpendling om du har lite kortare till jobbet. Damcyklar kallas även för lowstep och trots att namnet antyder annat, så passar den både för dam och herr. Tänk dock på att storleken på ramen bör passa din längd.",
  herrcykel:
    "för lite kortare cykling. Till exempel till matbutiken eller inne i stan. Passar även till jobbpendling om du har lite kortare till jobbet. Herrcyklar kallas även för highstep och trots att namnet antyder annat, så passar den både för dam och herr. Tänk dock på att storleken på ramen bör passa din längd.",
  hopfällbar:
    "för dig som behöver en liten och smidig cykel som du kanske kan ta med på husvagnssemestern eller på pengeltåget.",
  "hybridcykel, dam":
    "för de flesta aktiviteter. Den lite mer framåtlutande ställningen gör att den även funkar bra till lite längre pendlingar till och från jobbet.",
  "hybridcykel, herr":
    "för de flesta aktiviteter. Den lite mer framåtlutande ställningen gör att den även funkar bra till lite längre pendlingar till och från jobbet.",
  lådcykel:
    "för dig som antingen vill skjutsa barn eller frakta hem mängder med matkassar från matbutiken.",
  lastcykel:
    "om du vet med dig om att du kommer köra med till exempel matkassar eller kartonger.",
  mountainproduct:
    "om du ska köra mycket off-road och i skogar eller berg. Behöver du en mountainproduct vet du ofta att det är en mountainproduct du behöver. Ska du bara cykla till jobbet eller till matbutiken så är förmodligen inte en mountainproduct för dig.",
}

var motor_type_use_case = {
  framhjulsmotor:
    "är en relativt enkel motortyp att installera på en cykel och man kan därför hålla nere priset på dessa cyklar. En framhjulsmotor ger dock lite sämre cykelkännsla samt att risken att slira på halt underlag ökar",
  bakhjulsmotor:
    "sitter i bakhjulet och driver direkt hjulet utan någon kedja emellan. Detta gör att man motorn inte kan utnyttja växlarna och har därmed något sämre räckvidd än en mittmotor",
  mittmotor:
    "är i regel den dyrare varianten av de olika motortyperna men också den bättre. Tack vare att den tillåter att växla samt den nära anslutningen till tramporna så ger den en väldigt bra cykelkännsla samtidigt som den kan driva bakhjulet på ett väldigt effektivt sätt för att öka räckvidden",
}

var fewGearsText = [
  "Detta är relativt få växlar jämfört med snittet och cyklen lämnar sig därför bäst för platt stadscykling.",
  "Jämfört med andra cyklar är detta relativt lite. Har man få växlar kan backar vara jobbiga.",
  "Med så få växlar lämpar sig cykeln bäst i platta miljöer.",
]
var normalGearText = [
  "Detta får anses som ett ganska normal antal och cyklen fynkar i de flesta miljöer, både på platt mark och i backar.",
  "Cyklen funkar därför utmärkt både på platt mark och i backar.",
  "Med så många växlar kan du enkelt anpassa växeln till både backar och platt mark.",
]
var manyGearsText = [
  "Detta är i det övre spannet jämfört med andra cyklar och du kan fritt anpassa växeln till lutningen.",
  "Ofta är det endast mountainproducts som har så många växlar, för att man ska kunna cykla upp för riktigt branta backar. ",
  "Elcyklar har i regel mycket mindre antal växlar, då man enbart växlar i den bakre kasetten. Men ibland har man fler, som i det här fallet.",
  "Så många växlar har sina fördelar, framförallt att man kan anpassa växeln efter motståndet.",
]

function GetIntroduction(product) {
  return (
    <>
      {product.model} är en {product.frame_type} från{" "}
      <Link to={createBrandUrl(product.brand)}>
        {capitalizeFirstLetter(product.brand)}
      </Link>
      . Den har en {product.motor_type} och en batterikapacitet på{" "}
      {product.battery_capacity}.
    </>
  )
}
function GetMotorInformation(product) {
  return (
    <>
      En {product.motor_type} {motor_type_use_case[product.motor_type]}.{" "}
      {gt(readMoreList)}{" "}
      <Link to="/guider/motorplacering/" target="_blank">
        Motorplacering
      </Link>
      .
    </>
  )
}

function GetBatteryInformation(product) {
  return (
    <>
      {gt(batteryIndication)} {gt(batteryMoreFactors)} {gt(readMoreList)}{" "}
      <Link to="/guider/elcykelbatterier/" target="_blank">
        Elcykelbatterier
      </Link>
      .
    </>
  )
}

function GetUseCase(product) {
  return (
    <>
      En {product.frame_type} {gt(suitableList)}{" "}
      {frame_type_use_case[product.frame_type]}
    </>
  )
}

function GetGearText(product) {
  var gearText = ""
  if (product.number_of_gears < 7) {
    gearText = gt(fewGearsText)
  } else if (product.number_of_gears < 11) {
    gearText = gt(normalGearText)
  } else {
    gearText = gt(manyGearsText)
  }
  return (
    <>
      Cykeln har {product.number_of_gears} växlar. {gearText}
    </>
  )
}

function GetRangeInformation(product) {
  var rangeText = ""
  if (product.average_range_km < 55) {
    rangeText = gt(rangeLess1) + gt(rangeLess2)
  } else if (product.average_range_km > 65) {
    rangeText = gt(rangeAbove1) + gt(rangeAbove2)
  } else {
    rangeText = gt(rangeMid1) + gt(rangeMid2)
  }
  return (
    <>
      {gt(rangeDescriptionText)}
      {product.average_range_km}
      {rangeText}
    </>
  )
}

var currentIteration = 0
var product_id = 0

function gt(pickle_list) {
  var index = generateSemiRandomNumber(
    product_id,
    currentIteration++,
    pickle_list.length
  )
  if (currentIteration > ("" + product_id).length - 1) {
    currentIteration = 0
  }

  return pickle_list[index]
}

function ProductDescriptionIntro({ product }) {
  product_id = product.product_id
  return (
    <div className="shortDescription">
      <p>
        {GetIntroduction(product)} {GetMotorInformation(product)}
      </p>
    </div>
  )
}

function ProductDescriptionBody({ product }) {
  product_id = product.product_id
  if (product.ai_description.length > 0) {
    return (
      <div
        className="shortDescription"
        dangerouslySetInnerHTML={{ __html: product.ai_description }}
      ></div>
    )
  }

  return (
    <div className="shortDescription">
      <p>{GetBatteryInformation(product)}</p>
      <p>{GetRangeInformation(product)}</p>
      <p>{GetUseCase(product)}</p>
      <p>{GetGearText(product)}</p>
    </div>
  )
}

function GetIntroCompare(product, compareProduct) {
  var introText = gt([
    "Cykeln som är mest lik är ",
    "Den cykel som liknar mest är ",
    "Cykeln med mest liknande egenskaper är ",
  ])
  return (
    <>
      {introText}{" "}
      <Link to={createProductUrl(compareProduct)}>
        {capitalizeFirstLetter(compareProduct.brand) +
          " " +
          compareProduct.model}
      </Link>
      .
    </>
  )
}

function GetBrandCompare(product, compareProduct) {
  var brandCompareText = ""
  if (product.brand === compareProduct.brand) {
    brandCompareText = gt([
      "Cyklarna har samma märke ",
      "Bägge cyklarna kommer från samma tillverkare ",
      "Båda cyklarna är tillverkade av " +
        capitalizeFirstLetter(product.brand) +
        " ",
    ])
    brandCompareText += gt([
      "vilket kan göra dem enklare att jämföra. ",
      "vilket underlättar om du vill jämföra dem. ",
      "och det är då enklare att jämföra dem. ",
    ])
    brandCompareText += gt([
      "En enskild tillverkare tenderar att hålla ungefär samma kvalitet på sina cyklar och prissätta enligt samma principer. ",
      "Både prisbild och kvalitet på cyklar tenderar att likna varandra om de kommer från samma tillverkare. ",
      "Sett till pris och kvalitet så kan man förvänta sig att dessa är hyffsat lika hos en och samma tillverkare",
    ])
    brandCompareText += gt([
      "Det kan dock skilja i val av komponenter beroende på om det är en premiumcykel eller mer budget. ",
      "Däremot bör man vara observant på att en tillverkare kan ha olika budgetnivårer där man använder olika dyra komponenter. ",
    ])
  } else {
    brandCompareText = gt([
      "Cyklarna har olika märken ",
      "Cyklarna kommer från olika tillverkare ",
      "Ena cykeln är tillverkad av " +
        capitalizeFirstLetter(product.brand) +
        " medans den andra är tillverkad av " +
        capitalizeFirstLetter(compareProduct.brand) +
        " ",
    ])
    brandCompareText += gt([
      "vilket kan göra dem lite svårare att jämföra. ",
      "vilket ibland gör att det kan bli svårt att jämföra dem. ",
      "och man kan då behöva vara lite mer noggran när man jämför dem. ",
    ])
    brandCompareText += gt([
      "De olika tillverkarna kan ha helt olika kvaliteter på sina cyklar och prissätta utifrån olika parametrar. ",
      "Både prisbild och kvalitet på cyklar kan skilja sig mycket mellan olika tillverkare. ",
      "Sett till pris och kvalitet så kan man förvänta sig att detta kan skillja sig mellan olika tillverkare. ",
    ])
    brandCompareText += gt([
      "Däremot kan olika tillverkare fortfarande använda sig utav samma komponenter när det kommer till exempelvis växlar, motor och bromsar. ",
      "Däremot tillverkar tillverkarna sällan komponenter så som växlar motor och bromsar själva, utan dessa är ganska standard och kan vara samma mellan tillverkare. ",
    ])
  }
  return <>{brandCompareText}</>
}

function GetPriceCompare(product, compareProduct) {
  var text = ""
  var cheapProduct =
    product.prices[0] > compareProduct.prices[0]
      ? compareProduct.fields.full_name
      : product.full_name
  var expensiveProduct =
    product.prices[0] <= compareProduct.prices[0]
      ? compareProduct.fields.full_name
      : product.full_name
  var priceDiffKr = Math.abs(product.prices[0] - compareProduct.prices[0])
  if (priceDiffKr === 0) {
    text =
      gt([
        "Båda cyklarna kostar lika mycket. ",
        "Priset på cyklarna är samma. ",
        "Det är ingen skillnad i på cyklarna. ",
      ]) +
      gt([
        "Därför bör man istället fokusera på komponenterna och kanske välja den cykel där man får mest för pengarna. ",
        "Och man kan istället välja att kolla mer på komponenterna för att se vart man får bäst. ",
        "Men även om priset är samma kan komponenterna och kvaliteten skilja mycket. ",
      ])
  } else if (priceDiffKr / product.prices[0] < 0.05) {
    text =
      gt([
        "Båda cyklarna kostar ungefär lika mycket ",
        "Priset på cyklarna är nästan samma. ",
        "Det är inte så stor skillnad i pris på cyklarna. ",
      ]) +
      gt([
        "Så även om man kan spara lite på att köpa den billigare cykeln, så ska man vara medveten om att det kan finnas viss skillnad mellan cyklarna när det kommer till hållbarhet och kvalitet. ",
        "Har du en tajt budget kanske du vill välja den lite billigare, annars bör man även jämför andra aspekter så som kvalitet och komponenter. ",
      ])
  } else {
    text =
      gt([
        "Det är stor skillnad i pris på cyklarna. ",
        "Priset på cyklarna skiljer sig mycket. ",
      ]) +
      gt([
        "Det kan därför vara bra att väga in priset när man jämför cyklarna, och inte bara komponenter så som motor och växlar. ",
        "Du kan därför spara mycket genom att välja den billigare cykeln, om övriga egenskaper är liknande. ",
      ])
  }

  if (priceDiffKr !== 0) {
    text +=
      gt([
        "Den billigare cykeln i det här fallet är ",
        "Den billigare cykeln är ",
        "Billigast av de två är ",
        "Vill du spara pengar bör du alltså köpa ",
      ]) +
      capitalizeFirstLetter(cheapProduct) +
      ". " +
      capitalizeFirstLetter(expensiveProduct) +
      gt([
        " är lite dyrare.",
        " är dyrast.",
        " kostar mer.",
        " får du betala mer för.",
      ])
  }
  return <>{text}</>
}

function ProductCompareText({ product, compareProduct, site }) {
  product_id = product.product_id

  if (site.toLowerCase() === "alltomtraning") {
    return <div></div>
  } else {
    return (
      <div>
        <h3>
          {capitalizeFirstLetter(product.full_name)} eller{" "}
          {capitalizeFirstLetter(compareProduct.fields.full_name)}?
        </h3>
        <p>
          {GetIntroCompare(product, compareProduct)}{" "}
          {GetBrandCompare(product, compareProduct)}
        </p>
        <p>{GetPriceCompare(product, compareProduct)}</p>
      </div>
    )
  }
}

export { ProductDescriptionIntro, ProductDescriptionBody, ProductCompareText }
